
// Navigation toggle

window.addEventListener('load', function() {
	
	var menu = document.getElementById('navigationMenu');
	var openButton = document.getElementById('navigationOpen');
	var closeButton = document.getElementById('navigationClose');
		
	function navigationOpen() {
		menu.classList.add('open');
		console.log('menu open');
	}
	
	function navigationClose() {
		menu.classList.remove('open');
		console.log('menu close');
	}
	
	function navigationIsOpen() {
		return menu.classList.contains('open');
	}
	
	function navigationToggle() {
		if (navigationIsOpen()) {
			navigationClose();
		} else {
			navigationOpen();
		}
	}
	
	openButton.addEventListener('click', navigationOpen, false);
	closeButton.addEventListener('click', navigationClose, false);

});
